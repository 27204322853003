import { useMutation, useQuery } from "@tanstack/react-query";
import { isNil, omitBy } from "lodash";
import { useState } from "react";
import { LegForm } from "./LegForm";
import type { LegFormDataResponse } from "./api/JobApi";
import {
	NewLegType,
	NewSupplierInvoiceType,
	jobApi,
	toasted,
	useLookupBusinessUnits,
	useLookupCurrencies,
	useLookupDrivers,
	useLookupGoods,
	useLookupLegTypes,
	useLookupLocations,
	useLookupSubcontractors,
	useLookupSupplierInvoiceTypes,
	useLookupTrailerTypes,
	useLookupTrailers,
	useLookupTruckTypes,
	useLookupTrucks,
} from "./helpers";
import { useDialog } from "./helpersReact";

type LegSelfFormProps = {
	id?: number;
	jobId?: number;
	onSubmit?: () => void;
};
const LegSelfForm = ({ id, jobId, onSubmit }: LegSelfFormProps) => {
	const data = useQuery({
		queryKey: ["jobApi.bff.bffLegFormDataDetail", id, jobId],
		queryFn: () => {
			if (jobId) {
				return {
					jobId,
					goodsIds: [],
					legType: NewLegType.Delivery,
					supplierInvoice: NewSupplierInvoiceType.NoInvoice,
				} satisfies LegFormDataResponse;
			}
			return jobApi.bff
				.bffLegFormDataDetail(Number(id))
				.then((data) => data.data);
		},
	});
	const update = useMutation({
		mutationKey: ["jobApi.bff.bffLegFormDataUpdate"],
		mutationFn: (data: LegFormDataResponse) =>
			jobApi.bff.bffLegFormDataCreate(data),
	});
	const lLegTypes = useLookupLegTypes();
	const lGoods = useLookupGoods(data.data?.jobId ?? undefined);
	const lLocations = useLookupLocations();
	const lSubcontractors = useLookupSubcontractors();
	const lDrivers = useLookupDrivers();
	const lTrucks = useLookupTrucks();
	const lTrailers = useLookupTrailers();
	const lBusinessUnits = useLookupBusinessUnits();
	const lTrailerTypes = useLookupTrailerTypes();
	const lTruckTypes = useLookupTruckTypes();
	const lSupplierInvoiceTypes = useLookupSupplierInvoiceTypes();
	const lCurrencies = useLookupCurrencies();
	if (!data.isFetchedAfterMount || !data.data?.jobId) {
		return <div>Loading...</div>;
	}
	return (
		<LegForm
			lLegTypes={lLegTypes}
			lGoods={lGoods}
			lLocations={lLocations}
			lSubcontractors={lSubcontractors}
			lDrivers={lDrivers}
			lTrucks={lTrucks}
			lTrailers={lTrailers}
			lBusinessUnits={lBusinessUnits}
			lTrailerTypes={lTrailerTypes}
			lTruckTypes={lTruckTypes}
			lSupplierInvoiceTypes={lSupplierInvoiceTypes}
			lCurrencies={lCurrencies}
			defaultValues={omitBy(data.data, isNil)}
			onSubmit={async (data) => {
				await toasted(
					update.mutateAsync(data as LegFormDataResponse),
					"Saving leg",
				);
				onSubmit?.();
			}}
		/>
	);
};

export const useLegFormDialog = () => {
	const [props, setProps] = useState<LegSelfFormProps>({});
	const [toggleDialog, dialog] = useDialog(
		<LegSelfForm onSubmit={() => toggleDialog(false)} {...props} />,
		props.id ? "Edit Leg" : "Add Leg",
	);
	const openDialog = (props: LegSelfFormProps) => {
		setProps(props);
		toggleDialog(true);
	};
	return [openDialog, dialog] as const;
};
